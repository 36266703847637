import React, { useState } from "react";
import "./Vacatures.css";
import VactoresData from "../../Data/Vacatures/Vacatures";

import { RxCountdownTimer } from "react-icons/rx";
import { MdLocationOn } from "react-icons/md";
import { Link } from "react-router-dom"; // Import Link component from React Router

const Vacatures = () => {
  return (
    <div className="w-full">
      <div className="lg:h-64 md:h-48 bg-slate-50 py-6 px-10">
        <h2 className="xl:text-4xl lg:text-2xl text-lg font-bold leading-loose">
          Schoonmaakbedrijf vacatures
        </h2>
        <p className="xl:text-xl md:text-base text-sm font-light py-6">
          Heb je interesse in een afwisselende, leuke baan binnen een dynamisch
          bedrijf? Solliciteer dan via onderstaand formulier op een van de
          openstaande schoonmaakbedrijf vacatures of stuur een
        </p>
      </div>
      <div className="flex flex-col gap-2 item w-full items-center py-2">
        {VactoresData.map((vacture) => (
          <div key={vacture.id} className=" w-5/6 my-2.5 mx-8">
            <div className="item-dec-main">
              <div className="item-sec">
                <Link to={`/over-ons/vacatures/${vacture.id}`}>
                  <div className="section-one">
                    <h2 className="lg:text-lg text-base">{vacture.title}</h2>
                    <div className="flex-item">
                      {vacture.houre && (
                        <div className="flex items-center lg:text-lg text-base">
                          <RxCountdownTimer style={{ color: "white" }} />
                          <span className="ml-2">{vacture.houre}</span>
                        </div>
                      )}
                      {vacture.located && (
                        <div className="d-flex items-center lg:text-lg text-base">
                          <MdLocationOn style={{ color: "white" }} />
                          <span className="ml-2">{vacture.located}</span>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="section-two">
                    <p className="md:text-base text-sm">Bekijk vacature</p>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Vacatures;
