import React from "react";
import { BrowserRouter as Router, Routes, Route,useParams } from "react-router-dom";
import Navinformation from "./components/Navformation/Navinformation";
import Home from "./pages/Home/Home";
import Vacatures from "./pages/Vacatures/Vacatures";
import DescPage from "./pages/DescPage/DescPage";
import Footer from "./components/Footer/Footer";
import Contact from "./components/Contact/Contact";
import TextImage from "./pages/TextImage/TextImage";
import Aboutus from "./pages/Aboutus/Aboutus";
import FeatureList from "./pages/FeatureList/FeatureList";
import { zakelijk, particulier } from "./Data/FeatureList/FeatureList";
import Brochure from "./pages/Brochure/Brochure";


const App = () => {
  /* const { title } = useParams();
  const getItemSections = (sectionName) => {
    console.log('title', title);
    const section = sectionName === "zakelijk" ? zakelijk : particulier;
    const item = section.items.find((item) => item.title === title);
    return item ? item.sections : [];
  }; */

  return (
    <Router>
      <Navinformation />

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/over-ons/Brochure" element={<Brochure />} />
        <Route path="/over-ons/Vacatures" element={<Vacatures />} />
        <Route path="/over-ons/Vacatures/:id" element={<DescPage />} />
        <Route path="/contact-us" element={<Contact />} />
        <Route path="/zakelijk" element={<FeatureList data={zakelijk} />} />
        <Route
          path="/zakelijk/:title"
          element={<PageWithParams sectionName="zakelijk" />}
        />
        <Route
          path="/particulier"
          element={<FeatureList data={particulier} />}
        />
        <Route
          path="/particulier/:title"
          element={<PageWithParams sectionName="particulier" />}
        />
        <Route path="/About-us" element={<Aboutus />} />
      </Routes>
      <Footer />
    </Router>
  );
};
function PageWithParams({ sectionName }) {
  const { title } = useParams();
  const getItemSections = () => {
    console.log('title', title);
    const section = sectionName === "zakelijk" ? zakelijk : particulier;
    const item = section.items.find((item) => item.title === title);
    return item ? item.sections : [];
  };

  return <TextImage sections={getItemSections()} />;
}

export default App;

