import React, { useState } from "react";
import { FaLinkedinIn, FaPhoneAlt } from "react-icons/fa";
import { IoMdMail } from "react-icons/io";
import { IoLocationSharp } from "react-icons/io5";
import { FaFacebookF } from "react-icons/fa6";
import logo from "../../components/assests/logo.webp";
import { FaInstagram } from "react-icons/fa";
import "./Footer.css";
import { IoIosArrowForward } from "react-icons/io";
import { FaTelegramPlane } from "react-icons/fa";
import { FaWhatsapp } from "react-icons/fa";
import rating from "../../components/assests/Rating.webp";
import { RouteMap } from "../../Data/RouteMap/RouteMap";

const Footer = () => {
  const [copiedText, setCopiedText] = useState(null);

  const handleCopy = (textToCopy) => {
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        setCopiedText(textToCopy); // Update state to indicate text has been copied
        setTimeout(() => setCopiedText(null), 1500); // Reset copiedText state after 1.5 seconds
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };
  return (
    <footer className="bg-[#f7f7f7] text-sm text-[#999999] mt-40 text-center lg:text-left">
      <div className="flex justify-center mx-[3%] md:mx-[5%] lg:mx-[8%] xl:mx-[10%] 2xl:[15%]  flex-col xl:flex-row  py-10">
        <div className="lg:basis-5/12 basis-0 pr-0 lg:pr-6 mb-8">
          <h3 className="text-lg text-[#333333] mb-4 font-bold">
            White&Bright Cleaning
          </h3>
          <p>
            White&Bright Cleaning is een bedrijf dat zich bezighoud met
            schoonmaak en gerelateerde activiteiten. Wij proberen ons te
            onderscheiden door goede service en het leveren van deskundig en
            betrouwbaar werk. Door ons te specialiseren in verschillende
            vakgebieden zijn we van alle markten thuis en kunnen wij u maatwerk
            leveren, waar uw behoefte ook ligt. Dit alles doen wij op een
            milieubewuste wijze voor zeer gunstige tarieven.
          </p>
        </div>
        <div className="basis-2/12 pr-2 mb-8">
          <h3 className="text-lg text-[#333333] mb-4 font-bold flex">
            Diensten
          </h3>
          <div className="flex flex-col gap-y-2">
            {RouteMap.map((route , index) => (
              <div className="flex" key={index}>
                <IoIosArrowForward className="primary-color" />
                <a
                  key={route.title}
                  href={route.url}
                  className="hover:text-black"
                >
                  {route.title}
                </a>
              </div>
            ))}
          </div>
        </div>

        <div className="basis-2/12 xl:pl-8 mb-8 flex flex-col items-center text-center">
          <h3 className="text-lg text-[#333333] mb-4 font-bold">
            Klantenvertellen
          </h3>
          <div className="bg-white w-full flex flex-col items-center max-w-[15rem]">
            <img src={rating} alt="" className="max-w-[12rem]" />
            <p className="text-xs ">53 beoordelingen</p>
            <div className="text-[#464343] mt-1 leading-3 flex flex-col p-2">
              <p>Klanten</p>
              <p className="font-bold">Vertellen</p>
            </div>
          </div>
        </div>
        <div className="basis-4/12 xl:relative ">
          <div className="xl:absolute border-t-4 border-[#00b0f0] w-64 bg-white min-h-[20rem] shadow-2xl top-[-6rem] right-[0] m-auto p-4 ">
            <div>
              <img
                src={logo}
                alt=""
                style={{ height: "100px", minWidth: "200px", margin: "auto" }}
              />
            </div>
            <div className="flex flex-col gap-y-3 pt-4">
              <div className="flex">
                <IoLocationSharp className="text-[#00b0f0] mr-2 text-lg" />{" "}
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    handleCopy(
                      "Steenplaetsstraat 6, Unit 4.14 2288AA Rijswijk"
                    );
                  }}
                  className="hover:text-black"
                >
                  Steenplaetsstraat 6, Unit 4.14 2288AA Rijswijk
                </a>
              </div>
              <div className="flex">
                <FaPhoneAlt className="text-[#00b0f0] mr-2 text-lg" />{" "}
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    handleCopy("+31 0645042700");
                  }}
                  className="hover:text-black"
                >
                  (+31) 645042700
                </a>
              </div>
              <div className="flex">
                <IoMdMail className="text-[#00b0f0] mr-2 text-lg" />{" "}
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    handleCopy("Info@white-bright.nl");
                  }}
                  className="hover:text-black"
                >
                  Info@white-bright.nl
                </a>
              </div>
              <div className="flex">
                <FaFacebookF className="text-[#00b0f0] mr-2 text-2xl" />
                <FaLinkedinIn className="text-[#00b0f0] mr-2 text-2xl" />
                <FaInstagram className="text-[#00b0f0] mr-2 text-2xl" />
                <FaTelegramPlane className="text-[#00b0f0] mr-2 text-2xl" />
                <FaWhatsapp className="text-[#00b0f0] mr-2 text-2xl" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
