import React, { useEffect, useState } from "react";
import HTMLFlipBook from "react-pageflip";
import { Document, Page, pdfjs } from "react-pdf";
import pdf from "./sample.pdf";
import "./style.css";
import useSound from "use-sound";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const Pages = React.forwardRef((props, ref) => {
  return (
    <div
      className="demoPage"
      ref={ref}
      style={{ width: props.width, height: props.height }}
    >
      <div>{props.children}</div>
    </div>
  );
});

Pages.displayName = "Pages";

function Flipbook() {
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);

   function onDocumentLoadSuccess({ numPages }) {
     setIsLoading(false);
     setNumPages(numPages);
   }
  const [playFlip] = useSound("/flip.mp3", { volume: 0.2 });
  const [numPages, setNumPages] = useState(6);
  const [isMobile, setIsMobile] = useState(false);
  const [dimensions, setDimensions] = useState({ width: 400, height: 570 });

  useEffect(() => {
    function handleResize() {
      const width = window.innerWidth;
      setIsMobile(width < 1246);
      if (width < 640) {
        // mobile
        setDimensions({ width: 400, height: 570 });
      } else if (width < 1024) {
        // tablet
        setDimensions({ width: 500, height: 700 });
      } else {
        // desktop
        setDimensions({ width: 600, height: 870 });
      }
    }

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  function onFlip(e) {
    const newPage = e.data; // Current page index from HTMLFlipBook
    setCurrentPage(newPage + 1); // FlipBook uses zero-based indexing
    playFlip();
  }

  return (
    <div className="min-h-screen w-full flex flex-col gap-5 justify-center items-center p-8 bg-pdf">
      {isLoading && <p>Loading...</p>}
      <HTMLFlipBook
        display="single"
        width={dimensions.width}
        height={dimensions.height}
        onFlip={(e) => onFlip(e.data)}
      >
        {isMobile
          ? Array.from({ length: numPages }, (_, i) => i + 1).map((pNum) => (
              <Pages
                key={pNum}
                number={pNum}
                width={dimensions.width}
                style={{
                  display: Math.abs(currentPage - pNum) <= 1 ? "block" : "none",
                }}
              >
                <Document file={pdf} onLoadSuccess={onDocumentLoadSuccess}>
                  <Page
                    pageNumber={pNum === 0 ? 1 : pNum}
                    width={dimensions.width}
                    renderAnnotationLayer={false}
                    renderTextLayer={false}
                    showCover={false}
                  />
                </Document>
                {pNum ? (
                  <p className="det">
                    Page {pNum} of {numPages}
                  </p>
                ) : (
                  <p></p>
                )}
              </Pages>
            ))
          : [...Array(numPages + 1).keys()].map((pNum) => (
              <Pages
                key={pNum}
                number={pNum}
                width={dimensions.width}
                // height={ dimensions.height }
              >
                <Document file={pdf} onLoadSuccess={onDocumentLoadSuccess}>
                  <Page
                    pageNumber={pNum}
                    width={dimensions.width}
                    renderAnnotationLayer={false}
                    renderTextLayer={false}
                    showCover={false}
                  />
                </Document>
                {pNum ? (
                  <p className="det">
                    Page {pNum} of {numPages}
                  </p>
                ) : (
                  <p></p>
                )}
              </Pages>
            ))}
        </HTMLFlipBook>
        </div>
  );
}

export default Flipbook;
