import React from "react";
import Flipbook from "../../components/FlipBook/Flipbook";
import {Link} from "react-router-dom";

function Brochure() {
  return (
    <div>
      <div className="page-header ">
        <div className="container-contact">
          <div className="py-24">
            <div className="w-full pb-2.5">
              <div className="text-5xl text-gray-100">
                <h1 className="font-bold inline-block">Brochure</h1>
              </div>
            </div>
            <hr />
            <div>
              <span className="text-gray-200 font-light text-base arrow">
                <Link to="/" className="relative pr-10">
                  {" "}
                  Home
                </Link>
                {/* <a href="/" className="relative pr-10">
                            Home
                          </a> */}
                Contact
              </span>
            </div>
          </div>
        </div>
      </div>
      <Flipbook />
    </div>
  );
}

export default Brochure;
